
import fr from "./data/fr.json";
import en from "./data/en.json";
import de from "./data/de.json";
import nl from "./data/nl.json";
import es from "./data/es.json";
import sv from "./data/sv.json";
// import { useURLs } from "./constants/api.urls";
// import { CLD_ENV } from "./constants/env";
// const url = useURLs[CLD_ENV].CLD_FE_URL;

export default defineI18nConfig((nuxt) => ({
  legacy: false,
  messages: {
    "fr": fr,
    "en": en,
    "de": de,
    "nl": nl,
    "es": es,
    "sv": sv,
  },
  // baseUrl: url// not working from here
}));
